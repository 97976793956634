.managed-button{
    margin-top: 2em;
    margin-bottom:2em;
  }
  
  .dark-gray{
    .simplebar-scrollbar:before{
        background-color:get-color(mkWhite);
    }
    background-color: get-color(mkGrayDark);
    color:get-color(mkWhite);
    .invert{
      background-color: get-color(mkWhite);
      color:get-color(mkGrayDark);
    }
    &.cards {
      .card, .card-title, p, .fa, a {
        color: get-color(mkWhite);
      }
    }
  }
  .turquoise{
    background-color:  get-color(mkTurquoise);
    color:get-color(mkWhite);
    .invert{
      background-color:get-color(mkWhite);
    color:  get-color(mkTurquoise);
    }
    &.cards {
      .card, p, .fa, a {
        color: get-color(mkWhite);
      }
      .card-title {
        color: get-color(mkGrayDark);
      }
    }
  }
  .red{
    background-color:  get-color(primary);
    color:get-color(mkWhite);
    .invert{
      background-color:  get-color(mkWhite);
      color:get-color(primary);
    }
    &.cards {
      .card, p, .fa, a {
        color: get-color(mkWhite);
      }
      .card-title {
        color: get-color(mkGrayDark);
      }
    }
  }
  .white{
    background-color:  get-color(mkWhite);
    color: get-color(mkBlack);
    .Hollow-White-Button, .Chevron-White-Button{
      color:get-color(mkBlack);
      border-color:get-color(mkBlack);
    }
    .invert{
      background-color:  get-color(mkBlack);
      color: get-color(mkWhite);
    }
    .button.hollow {
      color: get-color(primary);
      background-color: get-color(mkWhite);
      &:hover {
        color: scale-color(get-color(primary), $lightness: -50%);
      }
    }
    .get-started {
      .special-events {
          label {
              color: $medium-gray;
          }
          a.regional-quote-back {
              color: get-color(primary);
          }
      }
    }
  }
  .green{
      background-color: get-color(mkGreen);
      color:get-color(mkWhite);
      .invert{
        background-color: get-color(mkWhite);
        color:get-color(mkGreen);
      }
      &.cards {
        .card, p {
          color: get-color(mkWhite);
        }
        .card-title {
          color: get-color(mkGrayDark);
        }
      }
    }
  .purple{
      background-color: get-color(mkPurple);
      color:get-color(mkWhite);
      .invert{
       background-color: get-color(mkWhite);
        color:get-color(mkPurple);
      }
      &.cards {
      .card, .card-title, p, .fa, a {
        color: get-color(mkWhite);
      }
    }
  }
  .medium-gray {
    background-color: get-color(mkGray60);
    color:get-color(mkGrayDark);
    .invert{
      background-color: get-color(mkGrayDark);
      color:get-color(mkGray20);
    }
    &.cards {
      .card, p {
        color: get-color(mkWhite);
      }
      .card-title {
        color: get-color(mkGrayDark);
      }
    }
  }
  .dark-gray, .turquoise, .red, .green, .purple, .medium-gray {
    .paragraph {
      color:get-color(mkWhite);
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, li {
          color:get-color(mkWhite);
        }
    }
    a {
      color: get-color(mkWhite);
    }
    label {
      color: get-color(mkWhite);
      &.mi-required::before {
        color: get-color(mkWhite);
      }
    }
    .field-validation-error {
      color: get-color(mkWhite);
    }
    .help-text {
      color: get-color(mkWhite);
    }
    &.callout .close-button {
      color: get-color(mkWhite);
    }
    .button.hollow {
      color: get-color(mkWhite);
      border: 2px solid get-color(mkWhite);
      &:hover {
        color: scale-color(get-color(mkWhite), $lightness: -25%);
        border: 2px solid scale-color(get-color(mkWhite), $lightness: -25%);
      }
    }
    &.alert-modal-content {
      .alert-modal-header, .alert-modal-icon {
        color: get-color(mkWhite);
      }
    }
  }
  
  .button.mkWhite {
    color: get-color(primary);
  }
  .white {
    .button.mkWhite {
      border: 2px solid get-color(primary);
      &:hover {
        border: 2px solid get-color(mkGrayDark);
      }
    }
  }
  