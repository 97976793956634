.registration-form {
    .has-tip {
        border-bottom: 0;
        &::after {
            content: '\f29c';
            margin-left:0.3em;
            font-family: "FontAwesome";
            //font-size:xx-large;
        }
    }
}