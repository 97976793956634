[data-joyride]{display:none}.reveal.joyride,.tooltip.joyride{font-size:100%;min-width:20rem;padding:.5em}.reveal.joyride .close,.tooltip.joyride .close{float:right}.reveal.joyride .joyride-buttons,.tooltip.joyride .joyride-buttons{text-align:center}.reveal.joyride .joyride-buttons button,.tooltip.joyride .joyride-buttons button{margin:0 .5em}[data-whatinput=mouse] .joyride{outline:0}
.button[data-joyride-next], .button[data-joyride-prev]{
    @include button(false, get-color(sfInteractive), scale-color(get-color(sfInteractive), $lightness: -15%), get-color(secondary), solid);
}
.close[data-joyride-close]{
    color:white;
    &:hover{
        text-decoration: none;
    }
}
.joyride-destination{
    height:0;
    width:0;
    color:transparent;
    background-color:transparent;
}