.Hollow-Blue-Button, .Hollow-Gray-Button, .Hollow-Red-Button, .Hollow-Dark-Gray-Button, .Blue-Button, .Dark-Gray-Button, .Gray-Button, .Red-Button, .Light-Gray-Button{
        &:hover{
            text-decoration: none;
        }
        @include shadowedLayer2
    }
.Red-Button {
    @include button(false, get-color(primary), scale-color(get-color(primary), $lightness: -15%), get-color(secondary), solid);
}
.Dark-Gray-Button {
    @include button(false, get-color(sfGrayPrimary), scale-color(get-color(sfGrayPrimary), $lightness: -15%), get-color(secondary), solid);
}
.Gray-Button {
    @include button(false, get-color(sfGray70), scale-color(get-color(sfGray70), $lightness: -15%), get-color(secondary), solid);
}
.Light-Gray-Button {
    @include button(false, get-color(sfGray25), scale-color(get-color(sfGray25), $lightness: -15%), #000000, solid);
}
.Blue-Button {
    @include button(false, get-color(sfInteractive), scale-color(get-color(sfInteractive), $lightness: -15%), get-color(secondary), solid);
}

.Hollow-Red-Button {
    @include button(false, get-color(primary), get-color(primary), get-color(primary), hollow);
}
.Hollow-Dark-Gray-Button {
    @include button(false, get-color(sfGrayPrimary), get-color(primary), get-color(primary), hollow);
}
.Hollow-Gray-Button {
    @include button(false, get-color(sfGray70), get-color(primary), get-color(primary), hollow);
}
.Hollow-Blue-Button {
    @include button(false, get-color(sfInteractive), get-color(primary), get-color(primary), hollow);
}

.card-section{
    .Hollow-Blue-Button, .Hollow-Gray-Button, .Hollow-Red-Button, .Hollow-Dark-Gray-Button, .Blue-Button, .Dark-Gray-Button, .Gray-Button, .Red-Button, .Light-Gray-Button{
        display:block;
    }
}