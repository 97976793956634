.checklist {

    li {
        position: relative;
        padding-bottom: 0;
        /* padding-left: 2em; */
    }
    .fa-check-square,
    .fa-check-square-o {
        font-size: 1.25rem;
        position: absolute;
        top: 1px;
        left: 0;
    }
}