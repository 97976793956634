header .menu>li>a, footer .menu>li>a {
    color: get-color(sfWhite);
    &:hover{
        //color:get-color(sfInteractive)
    }
}
.sfred{
    background-color:get-color(sfRed);
}
body{
    background-color: get-color(sfGray6); //dirty white
}
@include breakpoint(large down){
    main{
        padding: 0 0.5em;
    }
}
main{
    
    &>section{
        padding-bottom:3em;
    }
    article>section{
        padding: 1em 0;
    }
}
section{
    //background-color:$white;
    //padding-top:3em;
}
section:first-of-type{
    //padding-top:1em;
}

.process-cards .card{
    min-height:16rem;
}

// Setup each child of main as a container grid. This keeps the main content section setup as a pure container element.
body>main {
    min-height:10em;
}

.card img.card-icon, .center-image{
    display:block;
    margin:auto;
}
.grid-x>.scEmptyPlaceholder{
    @include xy-cell();
}
.editLabel{
    overflow:hidden;
    white-space: nowrap;
}
//This is some additional styles for eligibity
.occupationId {
    display: none;
}

.option-proxy {
    padding: 1em 1em;
}

.option-proxy:hover {
    background-color: darkblue;
    color: white;
    border-radius: 5px;
}
.ajax-refresh{
    transition: all 0.5s;
    filter:none;
}
.ajax-refresh.done{
    transform: rotateY(0deg);
}
.ajax-refresh.refreshing {
    transform: rotateY(90deg);
    //filter:blur(5px); //no ie11 support, so let's just 
}
.callout.scEnabledChrome img{
    max-height:100px;
    max-width:100px;
    width:auto;
    height:auto;
}
.cell.callout.backgroundImage{
    //background-size:100%;
    background-size:cover;
    text-shadow: 0 0 5px black;
    font-weight:bold;
    color:white;
    border:0;
    margin: 0; //Fill the container with the background image
    width: 100%; //This will not break dimensions due to max-width effecting the sizing classes
    div.displaceContent {
        max-width:50%;
    }
}
main .grid-padding-x>.cell {
    padding:1em;
}
main .orbit-next, main .orbit-previous{
    background-color:rgba(0,0,0,0.1);
    transform: translateY(-100%);
}
.callout:not(.alert):not(.warning):not(.primary):not(.secondary):not(.success):not(.backgroundImage){
    border-top:solid get-color(sfInteractive) 0.325em;
}
.grid-x.shadowed>.cell {
    &:not(.callout){
        background-color:get-color(sfWhite);
    }
    &.noshadow{
        background-color:transparent;
        box-shadow:none;
        text-shadow: 0 6px 12px rgba(0,0,0,0.12);//get-color(sfGray25); //this draws a shadow like the boxes have with text
        &:hover{
            box-shadow:none;
        }
    }
//section.grid-container>.grid-x>.cell {
//.cell.callout, .cell.card, .cell.accordion, .orbit, .cell.shadowed {
    @include shadowedLayer2;
    .flex-video, .responsive-embed{
        margin-bottom:0;
    }
    .accordion{
        margin-bottom:0;
    }
}
main .grid-padding-x>.breadcrumbs.cell{
    //padding-bottom:0;
    margin:0;
}
.drilldown .is-drilldown-submenu-parent.is-active>a::after{
  border-color:transparent transparent transparent get-color(sfWhite);
}
.accordion-title{
  color:$body-font-color;
  background-color:get-color(sfGray12);
}
.accordion-title.is-active{
    background-color:get-color(sfGray25);
}

.off-canvas .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after{
    border-color: get-color(sfGray12) transparent transparent;
}
#offCanvasLeft{
    font-weight:bold;
    //background-color:get-color(sfGray12);
    background: url('../img/NSTexturedFullScreenBackgroundColor.png');
    &.off-canvas .menu li a{
        color: get-color(sfGray12);
        margin-top:1em;
    }
    nav{
        padding-top:1em;
    }
}
header.cell{
    border-bottom: 0.3em solid get-color(primary);
}

main>section:first-of-type.grid-container {
    margin-top:1em;
}
.no-js .callout[data-closable]{
    display:block;
}
.callout[data-closable]{
    display: none;
}
#unhideHidden{
    display:none;
}

html.is-reveal-open, html.is-reveal-open body{
    position: static;
}

@include breakpoint(small only){
    .pagination li:first-child(){
        display:none;
    }
    .pagination li:last-child(){
        display:none;
    }
    .pagination .last-page, .pagination .first-page {
            display:none;
    }
    .pagination li.next-page, .pagination li.previous-page{
        display:inline-block;
    }
    
    .pagination .last-page a, .pagination .next-page a{
        &::before{
            content: attr(title) " ";
        }
    }
    .pagination .first-page a, .pagination .previous-page a{
        &::after{
            content: " " attr(title);
        }
    }
}

@each $size, $headers in $header-styles{
    @include breakpoint($size){
        @each $header, $header-defs in $headers {
            $font-size-temp: 1rem;
            ul.accordion-#{$header} .accordion-title{
                @if map-has-key($header-defs, font-size) {
                    $font-size-temp: rem-calc(map-get($header-defs, font-size));
                    font-size: $font-size-temp;
                  } @else if map-has-key($header-defs, fs) {
                    $font-size-temp: rem-calc(map-get($header-defs, fs));
                    font-size: $font-size-temp;
                  } @else if $size == $-zf-zero-breakpoint {
                    font-size: $font-size-temp;
                  }
                  @if map-has-key($header-defs, line-height) {
                    line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
                  } @else if map-has-key($header-defs, lh) {
                    line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
                  } @else if $size == $-zf-zero-breakpoint {
                    line-height: unitless-calc($header-lineheight, $font-size-temp);
                  }
                    margin-bottom:0;
                    &:before{
                        transform:translateY(-50%);
                        margin-top:0;
                    }
                  /*@if map-has-key($header-defs, margin-top) {
                    margin-top: rem-calc(map-get($header-defs, margin-top));
                  } @else if map-has-key($header-defs, mt) {
                    margin-top: rem-calc(map-get($header-defs, mt));
                  } @else if $size == $-zf-zero-breakpoint {
                    margin-top: 0;
                  }
                  @if map-has-key($header-defs, margin-bottom) {
                    margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
                  } @else if map-has-key($header-defs, mb) {
                    margin-bottom: rem-calc(map-get($header-defs, mb));
                  } @else if $size == $-zf-zero-breakpoint {
                    margin-bottom: rem-calc($header-margin-bottom);
                  }*/
            }
        }       
    }
}