.menu .news-event-listing a{
        padding:0;
}
.menu .news-event-listing .desc{
    padding-left:0.5em;
}
.menu .news-event-listing .title.h6 {
    margin:0;
    padding:0;
    font-weight:bold;
}
.menu .news-event-listing .h6 {
    padding-left:0.5em;
    margin:0;
}