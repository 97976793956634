.alert-bar-close{
    position:absolute;
    top:0.2em;
    right:0;
    letter-spacing:0.5em;
    padding-right:0.5em;
    cursor: pointer;
}
.alert-title {
    cursor: pointer;
    font-weight: bolder;
    padding-right: 60px;
    & p {
        display: inline;
    }
}
.alert-message{
    max-height:0;
    transition: max-height 0.4s ease-in-out, height 0.4s ease-in-out;
    overflow-y: hidden;
}
/*.alerts.overlay{
    position:absolute;
    z-index:500;
    width:100%;
    opacity:0.9;
}*/
.alert-bar {
    position:relative;
    //color:white;
    max-height:0;
    transition: max-height 0.4s ease-in-out, height 0.4s ease-in-out, padding 0.4s ease-in-out;
    clear:both;
    overflow-y: hidden;
    padding:0 0 0 1em;
    p {
        margin: 0;
        line-height: normal;
    }
}
.alert-bar.shown, .alert-message.shown {
    max-height: 600px;
    height: auto;
    padding:0.2em 0 0.2em 1em;
    &.alert-message{
        padding:0;
    }
}
// .alert-bar-red {
//     background-color: #b71234;
//     a {
//         color: #fff;
//     }
// }
// .alert-bar-black {
//     background-color: #222e2e;
//     a {
//         color: #fff;
//         &:hover{
//             color:#cfcfcf;
//         }
//     }
// }
// .alert-bar-gray {
//     background-color: #616365;
//     a {
//         color: #fff;
//         &:hover{
//             color:#cfcfcf;
//         }
//     }
// }
// .alert-bar-white {
//     background-color: #fff;
//     color: #222e2e;
// }

/* Modal */
.alert-modal.shown {
    display: block;
    z-index: 9000;
    height: 100%;
    width: 100%;
}
.alert-modal-close {
    float: right;
    font-size: 36px;
    cursor: pointer;
	&:hover {
		color: #000000;
		}
}
.alert-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -99999;
    height: 0;
    width: 0;
    //display: none;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
}
.alert-modal.shown {
    opacity: 1;
}
.shown .alert-modal-content{
    top:50%;
}
.alert-modal-content {
    position: absolute;
    top: -50%;
    left: 50%;
    transition: top 0.2s ease-in;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 95%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
}
.alert-modal-header {
    color: get-color(primary);
    font-weight: 700;
    //padding: .7em 0 .5em 0;
    padding: 0 0 .5em 0;
    font-size: 28px;
    display:flex;
    h2 {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 0px;   
        //padding-left:35px;
        display:inline-block;
    }
    i.alert-modal-icon + h2{
        padding-left:0;
    }
}
.alert-modal-body.icon-present {
    padding: 0 35px;
}
.alert-modal-overlay {
    z-index: 99998;
}
.alert-modal-icon {
    font-size: 28px;
    font-weight: 700;
    flex: 35px 0 0;
}

/* Growl */
.growls {
    position: fixed;
    bottom: 5%;
    right: 0;
    width: 300px;
    z-index: 999;
    //padding: 10px;
    transition: right .5s ease-in-out;
}
.alert-growl.shown{
    right: 0;
    height: 100px;
    padding: 10px;
    margin-top: 0.5rem;
}
.alert-growl-close {
    font-size: 36px;
    cursor: pointer;
    position: absolute;
    top: -1rem;
    right: 0.5rem;
}
.alert-growl{
    //position:fixed;
    position:relative;
    bottom:5%;
    right:-110%;
    width:300px;
    z-index:999;
    //padding:10px;
    box-shadow:0px 0px 20px rgba(0,0,0,0.8);
    top: -110%;
    //height:100px; 
    height:0;
    overflow:hidden;
    transition: right 0.5s ease-in-out, height 0.5s ease-in-out;
}

@include breakpoint (small only) {
    .alert-growl, .growls{
        width:100%;
        bottom:0;
        margin: 0;
        &.shown {
            margin-top: 0;
        }
    }
    .growls {
        max-height: 100px;
        padding: 0;
    }
}

.alert-growl-icon {
    font-size: 16px;
    //font-weight: 700;
}
.alert-growl-header {
    & p {
        display: inline;
    }
}

.callout.alert-callout {
    border-top: 0!important;
    margin-bottom: 0;
    &:not(.shown) {
        max-height: 0;
        overflow: hidden;
        border: 0;
        margin: 0;
        padding: 0;
    }
    &[data-closable] {
        display: block;
    }
}

.alerts.below-menu{
    // position:absolute;
    z-index:2;
    width:100%;
}