/* Progress bar */
.item-countdown {
    width: 100%;
    text-align: center;
    display: none;
}

.item-countdown.open-countdown {
    display: block;
}

.item-countdown ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;
}

.item-countdown ul li {
    padding: 0;
    display: block;
    position: relative;
    float: left;
    width: 60px;
    text-align: left;
}

.item-countdown ul.decline li {
    opacity: .3;
}

.item-countdown ul.decline li a span {
    background-color: #cbcbcb;
    color: #000;
    border: 4px solid #cbcbcb;
}

.item-countdown ul.decline li.decline {
    opacity: 1;
}

.item-countdown ul li#countdown-question4 {
    width: 30px;
}

.item-countdown ul li > span {
    display: inline-block;
    position: relative;
    cursor: default;
    text-decoration: none;
    text-align: center;
}

.item-countdown ul li span span {
    border-radius: 100%;
    height: 30px;
    width: 30px;
    line-height: 22px;
    display: block;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: get-color(sfGray25);
    color: #000;
    border: 4px solid get-color(sfGray25);
}

.item-countdown ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    right: 20px;
    color: #fff;
    font-size: 14px;
    font-family: arial,helvetica,sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}

.item-countdown ul li.active + .fp-tooltip {
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    width: auto;
    opacity: 1;
}

.item-countdown ul li.active span span {
    background-color: #0a70bc;
    color: #fff;
    border: 4px solid #0a70bc;
}

.item-countdown ul li.decline span span {
    background-color: #e1261c;
    color: #fff;
    border: 4px solid #e1261c;
}

.countdown-progress {
    height: 4px;
    width: 30px; 
    display: inline-block; 
    position: absolute;
    top: 13px;  
}

.active-bar .countdown-progress .countdown-progress-bar {
    width: 30px;
}

.countdown-progress-bar {
    height: 4px;
    width: 0;
    transition: width .3s ease-in-out;
    background-color: #0a70bc;

    .decline & {
        background-color: #cbcbcb;
    }
}