footer {
    background-color: $dark-gray;
    min-height:10rem;
    padding:2rem;
    color:$white;
    .disclaimer{
        padding-top:1rem;
    }
    a{
        color:white;
        &:hover{
            color:white;
        }
    }
    .contact-us-footer{
        text-align:center;
    }
    @include breakpoint(medium up){
        .disclaimer{
            //border-right: 1px solid get-color(sfWhite);
        }
        .contact-us-footer{
            text-align:right;
            p{
                margin-bottom:0;
            }
        }
    }
}