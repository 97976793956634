/**
 Global Header
*/
header{
    //@include shadowedLayer2;
    background-color:get-color(sfWhite);
    //border-bottom: 0.5em solid get-color(sfRed);
}
#user-menu{
    z-index: 12;
    width:auto;
    padding:0;
    min-width:120px;
    li a, li a:hover{
        color:get-color(sfInteractive);
    }
    hr{
        padding:0;
        margin:0;
    }
}
.visible-global-nav .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after{
    border-color:get-color(sfWhite) transparent transparent;
}
.global-header{
    
    padding:2em 0;
    @include xy-grid;
    
    .logo {
        vertical-align:baseline;
    }
    .title-banner-2{
        color:get-color(sfRed);
        font-style: italic;
        font-family:'Arial Narrow', Arial, sans-serif;
        font-size:24pt;
        font-weight:bold;
        display:inline;   
        margin:0;
        padding:0;     
    }
    .welcome{
        text-align:right;
        align-self: center;
    }
    @include breakpoint(medium down){
        & {
            //border-bottom: 0.5em solid get-color(sfRed);
            padding-top:1em;
            padding-bottom:1em;
        }
        .welcome{
            //text-align:right;
            font-weight: normal;
        }
        .title-cell{
            text-align: center;
            padding-bottom:0.5em;
        }
        .logout{
            display:block;
        }
        //.title-banner-2{
        //    font-size: 16pt;
        //    text-align:center;
        //}
        //.logo{
        //    height:16px;
        //}
    }
    @include breakpoint(medium only){
        
        .title-banner-2{
            font-size: 24pt;
        }
        .logo{
            height:24px;
        }
    }
    @include breakpoint(small only){
        .title-banner-2{
            font-size: 14pt;
        }
        .logo{
            height:14px;
        }
    }
}
#global-nav{
    @include shadowedLayer2Static;
    z-index:11;
    padding-top: 0.3em; //This balances out the border bottom of the header that should be present to divide the header in the event the menu is empty.
    padding-bottom: 0.3em;
    font-weight:bold;
    //We should analyse if this is necessary, or if there is a structure of the menu that can exist without the menu itself, however the mobile nature might kill that.
}
/**
Global navigation
*/
.global-nav{
    .dropdown.menu .is-active>a{
        color:get-color(sfWhite);
    }
    i.menu-toggler{
        text-align:right;
        padding-right:1em;
        width:100%;
        background-color:get-color(sfRed);
        font-size:12pt;
        color:get-color(sfWhite);;
    }
    
    nav{
        //margin-top:5px;
        position:absolute;
        left:-100%;
        transition:all 0.2s ease-in-out;
        z-index: 11;
        width:100%;
        &.visible-global-nav{
            left:0;
        }
    }
    
        background-color:get-color(sfRed);
        @media print,screen and (min-width: 40em){
        .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after{
            border-color:$white transparent transparent;    
        }
    }
    .dropdown.menu.vertical > li.opens-right > a::after{
        border-color:$white transparent transparent;
    }
    .is-dropdown-submenu.menu a {
        background-color:get-color(sfWhite);
        border-left:0.325em solid get-color(sfRed);
        color:get-color(sfGray85);
        &:hover{
            text-decoration: underline;
        }
    }
    .menu>li>a{
        background-color:get-color(sfRed);
        white-space: nowrap;
    }
    @include breakpoint(large up) {
        nav {
            position: static;
            margin:0;
        }
    }
}

.hamburger-icon {
    display:none;
    //float:right;
    width: 32px;
    height: 32px;
    position: relative;
    //margin-left:50%;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    span{
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        background: get-color(sfGrayPrimary);
        border-radius: 5px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1){
            top: 0px;
        }
        &:nth-child(2){
            top: 10px;
        }
        &:nth-child(3){
            top: 20px;
        }
    }
    &.open{
        span{
            &:nth-child(1){
                top: 12px;
                transform: rotate(135deg);
            }
            &:nth-child(2){
                opacity: 0;
                left: 60px;
            }
            &:nth-child(3){
                top: 12px;
                transform: rotate(-135deg);
            }
        }
    }
    @include breakpoint(medium down){
        &{
            display:block;
        }
    }
}
