nav.grid-x.grid-container+article{
    margin-top:0;
}

article {  
    &.grid-x.grid-container{
        @include shadowedLayer2;
        margin-top:1em;
        padding: 1em;
        background-color: get-color(sfWhite);
    }
    .toc-toggler{ display:none;}
    .classic-toc-toggler{
        display:none;
    }
    nav.classic-toc{
        //.fa.first-letter::before{
            //content: attr(title);
            //text-transform: uppercase;
            //font-family: $body-font-family;
            //font-weight:bold;
        //}
        ul{
            list-style: none;
            li{
                margin-left:1em;
            }
        }
    }
    nav.toc{
        position: fixed;
        right:0;
        z-index: 12;
        bottom: 50%;
        transform: translate(0, 50%);
        width: 4em;
        transition:all 0.5s;
        //box-shadow: 1px 1px 1em 0px;
        @include shadowedLayer2;
        max-height:95vh; //only allow this guy to grow to the height of the window;
        overflow:hidden;
        -webkit-overflow-scrolling: touch;
        overflow-y:auto;
        
        &::-webkit-scrollbar { width:5px; background-color: get-color(sfGray12); }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
            -webkit-border-radius: 5px;
            border-radius: 5px;
        }
        .title{
            @include element-invisible;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 5px;
            border-radius: 5px;
            background: get-color(sfGrayPrimary); 
            -webkit-box-shadow: inset 0 0 6px white; 
        }
        .toc-heading{
            display:none;
        }
        .fa.first-letter::before{
            content: attr(data-fl);
            text-transform: uppercase;
            font-family: $body-font-family;
            font-weight:bold;
        }
        ul{
            margin:0;
            padding:0;
            list-style: none;
        }
        li{
            text-align: center;
            border:1px solid get-color(sfWhite);
            background-color:get-color(sfInteractive);
            color:get-color(sfWhite);
            width:100%;
            overflow:hidden;
            //padding:0.125em;
            //padding-top:0.5em; //Alignment fix
            padding:0;
            transition:all 0.5s;
            //box-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000, 4px 4px #000, 5px 5px #000, 6px 6px #000, 7px 7px #000, 8px 8px #000;
            &:hover{
               //box-shadow: 1px 1px #007d9a, 2px 2px #007d9a, 3px 3px #007d9a, 4px 4px #007d9a, 5px 5px #007d9a, 6px 6px #007d9a, 7px 7px #007d9a, 8px 8px #007d9a;
               background-color:scale-color(get-color(sfInteractive), $lightness: -30%);
            }
            a{
                i {
                    border-bottom:none;
                    &::after {
                        //transition:width 0.5s;
                        content:attr(title);
                        display:block;
                        width:4em;
                        //padding-left:1em;
                        font-size:12px;
                        text-overflow:clip;
                        overflow:hidden;
                        white-space: nowrap;
                    }
                    font-size:32px;
                } //remove tool tip decoration
                height:4em;
                color:get-color(sfWhite);
                transition: all 1s;
                display:block;
                padding-top:0.5em;
                //&:hover{
                //    transform: scale(1.05);
                    //color:scale-color(get-color(sfInteractive), $lightness: 80%);
                //}
            }
        }
    }
}
@include breakpoint(large up){
    article{
        nav.toc{
            &:hover{
                width:12em;
                li i::after{
                    width:12em;
                }
            }
        }
    }
    
}
@include breakpoint(large down){
    article{
        .toc-toggler{
            position: fixed;
            bottom:1em;
            right: 10px;
            width: 3em;
            height:3em;
            background-color:get-color(sfInteractive);
            display:block;
            &:hover{
                background-color:scale-color(get-color(sfInteractive), $lightness: -15%);    
            }
            border-radius:50%;
            text-align:center;
            color:get-color(sfWhite);
            @include shadowedLayer2Static;
            margin-bottom:0; //reset this setting inherited from layer2 shadow
            i{
                font-size:3.125em;
            }
        }
        .show+.toc-toggler{
            background-color:scale-color(get-color(sfInteractive), $lightness: -30%);
        }
        nav.toc{
            width:12em;
            li {
                //padding:0.125em;
                text-align:left;
                //padding-left:0.5em;
                a{
                    white-space: nowrap;
                    padding: 1em 0.5em;
                    display:block;
                }
                a i{
                    &::after {
                        content:attr(title);
                        display:inline-block;
                        width:12em; // This may not calc right since we differ font sizes
                        padding-left:1em;
                        font-size:12px;
                        text-overflow:ellipsis;
                        overflow:hidden;
                        white-space: nowrap;
                        vertical-align: middle;
                        text-align:left;
                    }
                }
            }
            transition: opacity 0.5s;
            transform: translate(110%,-10%);
            bottom:2em;
            opacity: 0;
            max-height:calc(90vh - 2em);
            &.show{
                opacity: 1;
                transform: translate(0, -10%);
                //bottom:1em; //Why did I do this?
            }
        }
    }
}
.tooltip.fixed-tool{
    position:fixed;
}