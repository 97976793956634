.eligibility label,
p.label-like {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1em;
}

.alert .fa-exclamation-circle {
    color: #fff;
    background-color: get-color(sfRed120);
    font-size: 30px;
    padding: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-right: .25em;
}

.checklist {
    padding-left: 1.5em;
}

.checklist li > ul {
    padding-left: 2em;
    margin-bottom: 1em;
}

.checklist label {
    width: 0;
}

.required-astrisk {
    color: get-color(sfRed);
}

.submitted input:invalid {
    border: 2px dashed get-color(sfRed);
}

.submitted input:valid {
    border: 2px solid black;
}

/* This is the style of our error messages */
.error {
    width  : 100%;
    padding: 0;
   
    font-size: 80%;
    color: white;
    background-color: get-color(sfRed);
    border-radius: 0 0 5px 5px;
   
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .error.active {
    padding: 0.3em;
  }

@media screen and (min-width: 580px) {
    .eligibility label,
    p.label-like {
        font-size: 1.25rem;
    }
}