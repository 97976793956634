#start-qq {
    margin-left: 1em;
}

#epli-eligibility.show {
    display: block;
}

.question-section {
    margin: 2em 0;
}

.question-section-radio-buttons {
    margin-left: 1em;
}

#eligibility-conclusion {
    border-top: 1px solid #cecece;
    padding-top: 1.5em;
}

.cell .small-4{
    max-width: none;
}

.callout.alert{
    border: none;
}
.callout.no-padding{
    padding: 0;
}