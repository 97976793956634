
//map-set($foundation-palette, sfRed, #e1261c);
//map-set($foundation-palette, sfInteractive, #0a70bc);
  //primary: #1779ba,
  //secondary: #767676,
  //success: #3adb76,
  //warning: #ffae00,
  //alert: #cc4b37,
/*  primary: #e1261c, 
  secondary: #fff, 
  success: #029fdb, 
  warning: #ff9c3f, 
  alert: #f25214, 
  sfRed: #e1261c, 
  sfInteractive: #0a70bc
);*/
//$white: #fefefe;
//$button-background: get-color(sfInteractive);
$button-radius: 3px;
$tab-color: get-color(sfInteractive);
$tab-background-active: get-color(sfRed);
$tab-active-color: $white;
$topbar-background: get-color(sfRed);
$topbar-submenu-background: scale-color($topbar-background, $lightness: -15%);//#C31820;
$card-divider-background: $medium-gray;
$card-border: 1px solid $medium-gray;
$drilldown-arrow-color: get-color(sfInteractive);
$dropdown-menu-item-color-active: get-color(sfInteractive);
$accordionmenu-arrow-color: get-color(sfInteractive);
$accordionmenu-submenu-padding: 0.7rem 2rem;
//$global-radius:3px;
$body-font-color:get-color(sfGrayPrimary);
$accordion-item-background-hover:get-color(sfGray25);

//Custom
@mixin shadowedLayer1(){
    //box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.24);
    //transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    //margin-bottom:1em;
    //background-color:get-color(sfWhite);
    //&:hover{
    //    box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
    //}
}
  @mixin shadowedLayer2(){
    box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin-bottom:1em;
    background-color:get-color(sfWhite);

    //box-shadow: 0 6px 12px rgba(0,0,0,0.12), 0 6px 12px rgba(0,0,0,0.24);
    //transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    //margin-bottom:1em;
    //background-color:get-color(sfWhite);
    //&:hover{
    //    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    //}
}
  @mixin shadowedLayer2Static(){
    box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }