.stack td:before {
    content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
    font-weight: bold;

    // optional stuff to make it look nicer
    width: 6.5em; // magic number :( adjust according to your own content
    display: inline-block;
    @include breakpoint(large){
        display:none;
    }
}
article table {
    border: 1px solid get-color(sfGray25);
}
article table thead, article table tbody, article table tfoot {
    border: 0px;
}
