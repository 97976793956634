.sfsp-form {

    &.grid-x {
        padding: 1em;
        //width: 100%;
    }

}

    // input::placeholder, textarea::placeholder {
    //     font-style: italic;
    // }
    // input::-webkit-input-placeholder {
    //     font-style: italic;
    // }
    // input::-moz-placeholder {
    //     font-style: italic;
    // }
    // input::-webkit-datetime-edit-fields-wrapper {
    //     font-style: italic;
    // }
    input.input-validation-error::placeholder, textarea.input-validation-error::placeholder {
        color: $alert-color;
    }
    input.input-validation-error::-webkit-input-placeholder {
        color: $alert-color;
    }
    input.input-validation-error::-moz-placeholder {
        color: $alert-color;
    }
    input.input-validation-error::-webkit-datetime-edit-fields-wrapper {
        color: $alert-color;
    }

    // select,textarea,input:not([type=submit]):not([type=checkbox]):not([type=radio]):not(.search-box) {
    //     padding-right: 2.5em;
    //     border: 0;
    //     @include shadowedLayer1Focus;
    //     &:focus {
    //         border: 0;
    //     }
    // }
    // label {
    //     text-transform: uppercase;
    // }
    .help-text {
        position: relative;
        top: -1rem;        
    }
    .input-wrapper {
        position: relative;
        i.far, i.fas, i.fa, i.fab {
            position: absolute;
            top: 50%;
            right: 0.5em;
            transform: translateY(-50%);
            color: $medium-gray;
        }
    }


    select[size]:not([size="0"]):not([size="1"]) {
        background-image: none;
        height: auto;
    }

    .field-validation-error {
        font-size: smaller;
        color: $alert-color;
        display: block;
    }
    .input-validation-error {
        background-color: #f8e7eb;
        & + i.far, + i.fas, + i.fa, + i.fab {
            color: $alert-color;
        }
    }
    .is-invalid-input {
        & + i.far, + i.fas, + i.fa, + i.fab {
            color: $alert-color;
        }
    }
    .mi-recaptcha {
        margin-bottom: 1rem;
    }
    label.mi-required::before {
        content: '* ';
        color: $alert-color;
    }
