.filter-input-wrapper {
    position: relative;
}

.filter-input {
    margin: 0;
}

.filter-input-wrapper + .callout {
    margin-top: 1em;
}

.filter-list {
    margin: 0 .5em 0 0;
    padding: 0 2em;
    background-color: #fbfbfb;
    position: absolute;
    bottom: 38px;
    left: 0;
    width: 100%;
    border: 1px solid rgba(0,0,0,.2);
    max-height: 50vh;
    overflow-y: auto;

    li {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgba(0,0,0,.3);

        &:last-of-type {
            border-bottom: none;
        }

        a {
            padding: .5em 0;
            display: block;
            color: #000;
        }
    }
}